import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/syaokat.png";
import Particle from "../Particle";
import General from "./General";
import Button from "./Button";
import Type from "./Type";

import {
  AiOutlineSend,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";

function Home() {
  return (
    <>
	  <General />
      <Button />
    </>
  );
}

export default Home;
