import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/max.png";
import Tilt from "react-parallax-tilt";
import {
  AiOutlineSend,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Home2() {
  return (
	<>
    <Container fluid className="home-about-section" id="about">
		<div style={{ display: "grid", justify: "center", justifyItems: "center" }}>
		<a style={{ align: "center", justifyContent: "center", verticalAlign: "middle" }} href="https://t.me/syaokatrf">
		  <Col xs={4} md={2} className="tech-icons" style={{ minWidth: "4em", minHeight: "3em", display: "inline" }}>
			<img src="/icons/telegram.svg" alt="telegram" style={{borderRadius: '1em', width: '2em', verticalAlign: "middle", margin: "0.5em" }} />
		  </Col>
		</a>
	  </div>
    </Container>
	</>
  );
}
export default Home2;
