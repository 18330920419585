import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Усиленные мозги",
          "Высокий вольтаж",
          "Толкач и шарманка",
          "Русская передалка",
		  "Спасибо, покубаторю",
		  "Второй радиатор",
		  "Распределённая нагрузка",
		  "70 км/ч раздушен до 249 ватт",
		  "Солевые куколды нашли флажок",
		  "Износ руля",
		  "Дисплей ёмкости",
		  "Жать в палину",
		  "Мегасборщики суперсамокатов",
		  "Влагостойкие промпатшипнеки",
		  "Покрытие веском",
		  "Заменил амортизатор на пневмат",
		  "Язычок складирования",
		  "Слив для рекупа",
		  "Бугор торчит",
		  "Шеркать стало об поддрежку",
		  "Активный баланс",
		  "Как в аптеке",
		  "Промазать герметосом сортирным",
		  "Обмотку в звезду перепаял",
		  "Тормозной резистор",
		  "Обманка бмс",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
